import { useState, useEffect } from "react";
import "./App.css";
import QRCode from "qrcode.react";
import { saveAs } from "file-saver";
// import ReactGA from "react-ga";

// const TRACKING_ID = "G-EFJKV9VWV0"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [value, setValue] = useState("");
  const [size, setSize] = useState(0);

  const handleSave = (fileFormat) => {
    const canvas = document.querySelector("canvas");
    const appendFile = "qr-code-";
    let fileName = value;
    if (fileName.startsWith("https://")) {
      fileName = fileName.substring(8);
    }
    const date = new Date().toISOString().slice(0, 10);

    switch (fileFormat) {
      case "jpg": {
        canvas.toBlob((blob) => {
          saveAs(blob, `${appendFile}${fileName}-${date}.jpg`, {
            type: "image/jpeg",
          });
        });
        break;
      }
      default: {
        canvas.toBlob((blob) => {
          saveAs(blob, `${appendFile}${fileName}-${date}.png`);
        });
        break;
      }
    }
  };

  useEffect(() => {
    setSize(Math.min(window.innerWidth, window.innerHeight) * 0.8);
  }, []);

  return (
    <div className="container">
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Enter text or link to encode as QR code"
        className="input"
      />
      <div className="qrcode">
        <QRCode
          value={value}
          width={20}
          height={20}
          size={size}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>
      <button onClick={() => handleSave()} className="button">
        Save as PNG
      </button>
      <button onClick={() => handleSave("jpg")} className="button">
        Save as JPG
      </button>
    </div>
  );
}

export default App;
